<template>
  <v-row>
    <v-col cols="12">
      <v-alert
        text
        outlined
        color="#ddd"
        icon="mdi-file-image"
        class="text-center upload-alert"
        @click="$emit('uploadImage')"
      >
        <v-btn ref="imageUploadBtn" text :loading="loading" v-if="!loading">
          <v-icon left>mdi-upload</v-icon> Upload Images
        </v-btn>
        <v-progress-linear
          v-else
          :value="progress"
          height="10"
        ></v-progress-linear>
      </v-alert>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "ProductImageUploadEmptyState",
  props: ["loading", "progress"],
  data() {
    return {};
  },
};
</script>
<style scoped>
.upload-alert:hover {
  cursor: pointer;
}
</style>
